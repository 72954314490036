<template>
  <div
    class="d-flex small-content-height flex-column align-center justify-center"
  >
    <div class="mt-2 ml-4" style="width: 100%">
      <v-btn @click="$router.back()">
        <v-icon left>mdi-arrow-left</v-icon>
        {{ $t("quay_lai") }}
      </v-btn>
    </div>
    <div class="small-title mt-4">{{ $t("thong_tin") }}</div>
    <v-card class="pa-4" style="max-width: 1124px; width: 100%">
      <v-form ref="form" @submit.prevent="confirmInfo">
        <v-row>
          <v-col cols="12" md="6">
            <div v-if="!examination.checkInStudents">
              <v-select
                :rules="[required]"
                v-model="form.subjectId"
                :label="$t('mon_thi')"
                outlined
                dense
                :items="examination.subjects"
                item-text="fullName"
                item-value="id"
              ></v-select>
              <v-text-field
                v-model="form.studentName"
                :rules="[required]"
                :label="$t('ho_ten')"
                outlined
                dense
              ></v-text-field>
              <v-text-field
                v-model="form.studentCode"
                :rules="[required]"
                :label="$t('mssv')"
                outlined
                dense
              ></v-text-field>
              <v-text-field
                v-model="form.classId"
                :rules="[required]"
                :label="$t('ma_lop')"
                outlined
                dense
              ></v-text-field>
              <v-text-field
                v-model="form.studyGroupName"
                :rules="[required]"
                :label="$t('nhom_lop')"
                outlined
                dense
              ></v-text-field>
            </div>
            <div v-else>
              <v-select
                :rules="[required]"
                v-model="form.subjectId"
                :label="$t('mon_thi')"
                :placeholder="$t('chon_mon_thi')"
                outlined
                dense
                :items="studentSubjects"
                item-text="fullName"
                item-value="id"
                @input="subjectChange"
              ></v-select>
              <v-text-field
                :label="$t('stt')"
                :value="student.numbOrder"
                disabled
                outlined
                dense
              ></v-text-field>
              <v-text-field
                :label="$t('ho_ten')"
                :value="student.studentName"
                disabled
                outlined
                dense
              ></v-text-field>
              <v-text-field
                :label="$t('mssv')"
                :value="student.studentCode"
                disabled
                outlined
                dense
              ></v-text-field>
              <v-select
                v-if="needSelectClass"
                :rules="[required]"
                v-model="form.classId"
                :label="$t('ma_lop')"
                :placeholder="$('chon_ma_lop')"
                outlined
                dense
                :items="classes"
              ></v-select>
              <v-text-field
                v-else
                :label="$t('ma_lop')"
                :value="student.classId"
                disabled
                outlined
                dense
              ></v-text-field>
              <v-text-field
                :label="$t('nhom_lop')"
                :value="student.studyGroupName"
                disabled
                outlined
                dense
              ></v-text-field>
            </div>
            <p
              v-if="examination.isTesting && examination.checkInStudents"
              style="color:red"
            >
              {{ $t("ghi_chu") }}
            </p>
          </v-col>
          <v-col cols="12" md="6">
            <div
              class="px-8 d-flex justify-center align-center"
              style="height: 100%"
            >
              <canvas ref="img" style="width: 100%"></canvas>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="6">
            <v-layout>
              <v-btn
                color="secondary"
                @click="saveInfoToServer"
                :loading="saveInfoLoading"
              >
                <v-icon left>mdi-content-save</v-icon>
                {{ $t("luu_thong_tin") }}
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                type="submit"
                :loading="confirmInfoLoading"
              >
                <v-icon left>mdi-lead-pencil</v-icon>
                {{ $t("bat_dau_lam_bai") }}
              </v-btn>
            </v-layout>
          </v-col>
        </v-row>
      </v-form>
    </v-card>
  </div>
</template>

<script>
import { confirm, saveInfo, getForm } from "@/api/student-test";
import { mapState } from "vuex";

export default {
  data: () => ({
    form: {
      subjectId: undefined,
      studentName: undefined,
      studentCode: undefined,
      classId: undefined,
      studyGroupName: undefined,
      order: undefined,
    },
    hasSave: false,
    saveInfoLoading: false,
    confirmInfoLoading: false,
    selectedStudent: {},
  }),
  computed: {
    ...mapState("student", ["examination", "students"]),
    studentSubjects() {
      if (this.students) {
        return this.students.map((e) => e.examinationSubject.subject);
      } else {
        return [];
      }
    },
    student() {
      if (this.form.subjectId) {
        const studentSj = this.students.filter(
          (e) => e.examinationSubject.subject.id === this.form.subjectId
        );
        if (studentSj.length > 1 && this.form.classId) {
          return studentSj.find((e) => e.classId === this.form.classId);
        } else {
          return studentSj[0];
        }
      } else {
        return {};
      }
    },
    selectedStudents() {
      if (this.form.subjectId && this.students) {
        return this.students.filter(
          (e) => e.examinationSubject.subject.id === this.form.subjectId
        );
      }
      return [];
    },
    needSelectClass() {
      return this.selectedStudents.length > 1;
    },
    classes() {
      return this.selectedStudents.map((e) => e.classId);
    },
  },
  async mounted() {
    const img = this.$store.state.student.image;
    if (!img) {
      await this.$router.push({ name: "StudentConfirm" });
      return;
    }
    const canvas = this.$refs.img;
    canvas.width = img.width;
    canvas.height = img.height;
    const context = canvas.getContext("2d");
    context.drawImage(img, 0, 0, img.width, img.height);
    await this.getCurrentInfo();
  },

  methods: {
    required(e) {
      return !!e || "Trường này là bắt buộc";
    },
    subjectChange(e) {
      this.form.classId = undefined;
    },
    async getCurrentInfo() {
      const { data: form } = await getForm();
      if (form) {
        this.form.subjectId = form?.examinationSubject?.subject?.id;
        this.form.studentName = form.studentName;
        this.form.studentCode = form.studentCode;
        this.form.classId = form.classId;
        this.form.studyGroupName = form.studyGroupName;
      } else {
        if (!this.form.subjectId && this.studentSubjects.length > 0) {
          this.form.subjectId = this.studentSubjects[0].id;
        }
      }
    },
    async saveInfoToServer() {
      try {
        this.saveInfoLoading = true;
        if (!this.$refs.form.validate()) {
          return;
        }
        const img = this.$refs.img;
        const canvas = document.createElement("canvas");
        const context = canvas.getContext("2d");
        let w = 0,
          h = 0;
        if (img.width < 1024) {
          w = img.width;
          h = img.height;
        } else {
          w = 1024;
          h = (w * img.height) / img.width;
        }
        canvas.width = w;
        canvas.height = h;
        context.drawImage(img, 0, 0, w, h);
        const image = canvas.toDataURL("image/jpeg");
        const ipEmail = this.$store.state.student.snapshotWithCam;
        const result = await saveInfo({ ...this.form, image, ipEmail });
        this.hasSave = true;
        this.$snackbar(this.$t("luu_thanh_cong"), "success");
      } finally {
        this.saveInfoLoading = false;
      }
    },
    async confirmInfo() {
      try {
        this.confirmInfoLoading = true;
        if (!this.$refs.form.validate()) {
          return;
        }
        await confirm();
        await this.$router.push({ name: "StudentExam" });
      } finally {
        this.confirmInfoLoading = false;
      }
    },
  },
};
</script>
